@media only screen and (max-width: 576px) {
  .widget-inkedibles {
    &.widget-container {
      .widget-block {
        width: 75% !important;
      }

      .widget-upload {
        .widget-body {
          .widget-agreement {
            width: auto;

            .submit {
              margin-top: 10px;
              padding-left: 15px !important;
              text-align: center;

              .btn-sm {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .widget-inkedibles {
    .container-thankyou {
      height: 100vh !important;
    }
  }
}

@media only screen and (min-width: 1366px) {
  .widget-inkedibles {
    .container-thankyou {
      padding-bottom: 20px !important;
    }
  }
}
