/* Montserrat */
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3gTD_u50.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3g3D_u50.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3gbD_u50.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3gfD_u50.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Montserrat Light"), local("Montserrat-Light"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_cJD3gnD_g.woff2")
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/montserrat/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/montserrat/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/montserrat/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/montserrat/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/montserrat/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3gTD_u50.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3g3D_u50.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3gbD_u50.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3gfD_u50.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2")
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_bZF3gTD_u50.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_bZF3gbD_u50.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2")
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2")
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_c5H3gTD_u50.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_c5H3g3D_u50.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_c5H3gbD_u50.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_c5H3gfD_u50.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"),
    url("../fonts/montserrat/JTURjIg1_i6t8kCHKm45_c5H3gnD_g.woff2")
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* End Montserrat */

/* Open Sans */
/* cyrillic-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN_r8OX-hpOqc.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN_r8OVuhpOqc.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN_r8OXuhpOqc.woff2")
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN_r8OUehpOqc.woff2")
      format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN_r8OXehpOqc.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN_r8OXOhpOqc.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN_r8OUuhp.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/opensans/mem8YaGs126MiZpBA-UFWJ0bbck.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/opensans/mem8YaGs126MiZpBA-UFUZ0bbck.woff2") format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/opensans/mem8YaGs126MiZpBA-UFWZ0bbck.woff2") format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/opensans/mem8YaGs126MiZpBA-UFVp0bbck.woff2") format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/opensans/mem8YaGs126MiZpBA-UFWp0bbck.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/opensans/mem8YaGs126MiZpBA-UFW50bbck.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/opensans/mem8YaGs126MiZpBA-UFVZ0b.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UNirkOX-hpOqc.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UNirkOVuhpOqc.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UNirkOXuhpOqc.woff2")
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UNirkOUehpOqc.woff2")
      format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UNirkOXehpOqc.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UNirkOXOhpOqc.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UNirkOUuhp.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN7rgOX-hpOqc.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN7rgOVuhpOqc.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN7rgOXuhpOqc.woff2")
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN7rgOUehpOqc.woff2")
      format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN7rgOXehpOqc.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN7rgOXOhpOqc.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN8rsOX-hpOqc.woff2")
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN8rsOVuhpOqc.woff2")
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN8rsOXuhpOqc.woff2")
      format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN8rsOUehpOqc.woff2")
      format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN8rsOXehpOqc.woff2")
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN8rsOXOhpOqc.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url("../fonts/opensans/mem5YaGs126MiZpBA-UN8rsOUuhp.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* End Open Sans */

/* Product Sans */
@font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans"),
    url("../fonts/productsans/ProductSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Italic"),
    url("../fonts/productsans/ProductSans-Italic.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Thin Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Thin Regular"),
    url("../fonts/productsans/ProductSans-Thin.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Light Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Light Regular"),
    url("../fonts/productsans/ProductSans-Light.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Medium Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Medium Regular"),
    url("../fonts/productsans/ProductSans-Medium.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Black Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Black Regular"),
    url("../fonts/productsans/ProductSans-Black.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Thin Italic"),
    url("../fonts/productsans/ProductSans-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Light Italic"),
    url("../fonts/productsans/ProductSans-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Medium Italic"),
    url("../fonts/productsans/ProductSans-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Bold"),
    url("../fonts/productsans/ProductSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Bold Italic"),
    url("../fonts/productsans/ProductSans-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Product Sans Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Product Sans Black Italic"),
    url("../fonts/productsans/ProductSans-BlackItalic.woff") format("woff");
}
/* End Product Sans */

// Junction-regular
@font-face {
  font-family: "junction-regular";
  src: url("../fonts/junction/Junction-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Google Sans */
@font-face {
  font-family: "Google Sans";
  src: url("../fonts/googlesans/GoogleSans-Regular-400.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Google Sans";
  src: url("../fonts/googlesans/GoogleSans-Medium-500.woff2") format("woff2");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Google Sans";
  src: url("../fonts/googlesans/GoogleSans-Bold-700.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
}
/* End Product Sans */

/* Google Sans Text */
@font-face {
  font-family: "Google Sans Text";
  src: url("../fonts/googlesans/GoogleSansText-Regular-400.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Google Sans Text";
  src: url("../fonts/googlesans/GoogleSansText-Medium-500.woff2")
    format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Google Sans Text";
  src: url("../fonts/googlesans/GoogleSansText-Bold-700.woff2") format("woff2");
  font-style: normal;
  font-weight: 700;
}
/* End Product Sans Text */
