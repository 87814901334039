$orange-color: #e2582b;
$orange-hover-color: #ec673a;

$dark-color: #252525;
$dark-color-hover: #343a40;

$gray-color: #878f96;

$light-gray-color: #ced4da;

$red-color: #ff1212;

$disable-input-color: #eaeaea;

$primary-font: "Open Sans";
$secondary-font: "Montserrat";

$primary-font-semi-bold: 600;
$secondary-font-semi-bold: 500;
