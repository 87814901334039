@import "../../../../assets/styles/variables.scss";
@import "../../../../assets/styles/fonts.scss";
$brand-color: #ff0000;
$button-color: #ff0000;
$upload-color: #808080;
$background-color: #fff;
.widget-uiw.widget-container {
  padding: 0;
  background-image: url("../../../../assets/img/uiw/uiwBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: -1;
  }

  .widget-block.widget-welcome,
  .widget-block.widget-upload,
  .widget-block.widget-thankyou {
    overflow: auto;
    max-height: 100vh;
    width: 800px;
    border: 1px solid #ced4da;
    padding: 15px 0;
    padding-bottom: 0;
    padding-top: 0;
    .widget-header {
      padding: 15px;
      width: 100%;
      background-color: #fff;
      img {
        margin: 0 auto;
        display: block;
        width: auto;
        padding: 0;
        max-width: 100%;
        height: auto !important;
        max-height: 120px !important;
      }
    }

    .widget-body {
      .button-wrapper {
        margin: 20px 0 10px 0;
      }
    }

    .signature {
      padding: 0;
      min-height: 24px;
      line-height: 24px;
      font-size: 14px;
      background: #000;
      color: #fff;
      .logo {
        width: 100px;
      }
      p {
        width: 100%;
        height: 8px;
        margin: 0;
        font-weight: 500;
        font-size: 11px;
        min-height: 20px;
      }
    }
  }

  .widget-welcome {
    .widget-body {
      .logo {
        padding: 20px 0;
      }

      .row-uiw {
        width: 100%;
        position: relative;

        > img {
          width: 100px;
          height: 300px;
          margin: auto;
          display: block;
        }

        &.sign {
          > div {
            text-align: left;
            margin-top: 5px;

            > p {
              width: 100%;
              height: 8px;
              margin: 0;
              font-weight: 500;
              font-size: 11px;
              padding-left: 20px;

              > img {
                height: 100%;
              }
            }
          }
        }

        &.signature {
          margin: 1rem;
        }
      }

      .text-title {
        margin: 25px 0;
      }

      .textStyle {
        font-weight: 600;
        text-align: center;
        font-size: 20px;
        font-family: $primary-font;
        line-height: 1.6;
      }

      .textStyleBtn {
        font-size: 17px;
      }

      .text-container {
        padding: 0 60px;
      }

      .btn.btn-primary {
        background-color: $brand-color;
        border: 1px solid $brand-color;
        width: 180px;
        font-weight: bold;

        &:hover {
          opacity: 0.8;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .button-wrapper {
        margin: 20px 0 10px 0;
      }
    }

    .widget-footer {
      .button {
        margin: 0;
      }
    }
  }

  .widget-upload.widget-block {
    .formik-container {
    }
    .widget-body {
      .uiw-media {
        padding-top: 21px;
        padding-right: 21px;
        text-align: center;
      }
      .general-message {
        min-height: 31px;
        font-size: 14px;
        padding: 5px 0;
      }

      .success {
        color: $brand-color;
      }

      .error {
        color: #e60000;
      }

      .error-msg {
        min-height: 21px;
        margin: 0 10px;
      }

      .widget-form {
        padding: 0 15px;
        margin-top: 0;

        .section-hashtag {
          margin: 0 15px;
        }

        .add-margin {
          margin: 10px 15px !important;
        }

        .remove-margin {
          margin: 0 !important;
          margin-bottom: 35px !important;
        }

        .error-msg p,
        .form-group small {
          color: #e60000;
        }

        .form-group {
          // css-1pahdgx-control
          .react-select__menu {
            .react-select__menu-list {
              max-height: 250px !important;
            }
          }
          .react-select__control--is-focused {
            border-color: $button-color;
            box-shadow: 0 0 0 1px $button-color;
          }
          .react-select__control {
            &:hover,
            &:focus {
              border-color: $button-color;
              box-shadow: 0 0 0 1px $button-color;
            }
            .react-select__value-container {
              font-size: 0.875rem;
              .react-select__multi-value {
                color: #fff;
                background: $button-color;
                .react-select__multi-value__label {
                  color: #fff;
                }
              }
            }
          }
          .react-select__menu {
            font-size: 0.875rem;
            .groupStyles {
              display: flex;
              justify-content: space-between;
            }
          }
          .input-wrapper {
            .uploader-error-message {
              font-weight: 400;
              min-height: 18px;
            }
            .social-media-input {
              display: flex;
              padding-left: 8px;
              border: 1px solid #ced4da;
              border-radius: 0.2rem;
              span {
                line-height: 31px;
              }
              input {
                padding-left: 0;
                border: 0;
              }
            }
          }
        }

        .widget-hashtag {
          .list-hashtag {
            .hashtag.active {
              background-color: $brand-color;
            }
          }
        }
      }

      .text-wrapper {
        p {
          margin: 1.5rem 0;
        }
      }
      .widget-media {
        width: 250px;
        height: 250px;
        border: 1px solid #000;
        border-radius: 4px;
        margin: 0 auto;
        &:hover {
          .dropzone {
            box-shadow: none;
          }
        }
        .preview-img {
          .dropzone {
            background: #fff;
          }
          .video {
            background: $button-color;
          }
        }
        .preview-txt-default {
          svg {
            color: $upload-color;
          }
        }
      }
      .text {
        color: #000;
        font-size: 14px;
        margin: 0.5rem 0;
        text-align: center;
      }
      .hide {
        display: block !important;
      }
    }

    .widget-agreement {
      .form-check > input {
        height: 20px;
        width: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: 1px solid #ced4da;
        border-radius: 2px;
        outline: none;
        transition-duration: 0.3s;
        cursor: pointer;
      }

      .form-check > input:checked {
        border: 1px solid $brand-color;
        background-color: $brand-color;
      }

      .form-check > label {
        padding: 0.35rem;
        margin-left: 5px;
        font-weight: 400;
        color: #999;
        cursor: pointer;
      }

      .form-check > input:checked + label::before {
        content: "";
        display: flow-root;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        text-align: center;
        color: #fff;
        position: absolute;
        width: 7px;
        height: 12px;
        top: 6px;
        left: 6px;
      }

      .form-check-label {
        .term {
          color: $button-color;
        }
      }
    }

    .widget-footer {
      .button {
        color: #fff;
        background-color: $button-color;
      }
    }
  }

  .widget-thankyou {
    box-shadow: none;
    max-height: 100vh;
    width: 400px !important;
    .widget-header {
      margin: 0 auto;
    }
    .widget-body {
      .uploaded-media {
        img {
          width: 100%;
          max-height: 300px;
          object-fit: cover;
        }
      }

      .thankyou-content {
        padding: 15px;

        h5 {
          color: $brand-color;
          text-align: center;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 25px;
        }

        p {
          margin-bottom: 10px;
        }

        span {
          display: block;
        }
      }

      h3 {
        background-color: $upload-color;
      }
    }
    .widget-footer {
      button.btn {
        background-color: $button-color !important;
        border: 1px solid $button-color !important;
      }
    }
  }

  .widget-block {
    width: 400px;
    border: none;
    background-color: rgba(255, 255, 255, 1);

    .widget-agreement {
      a {
        color: $brand-color;
      }
    }

    .widget-footer {
      .btn.btn-primary {
        background-color: $brand-color;
        border: 1px solid $brand-color;

        &:hover {
          opacity: 0.8;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
