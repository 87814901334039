@import "./assets/styles/override.scss";
@import "./assets/styles/variables.scss";
@import "./assets/styles/fonts.scss";

*:not(.gemini *, .widget-teampixel *, .gemini-term *, .gemini-limit-terms *) {
  font-family: $primary-font, $secondary-font, "Arial", "sans-serif";
}

#cancel-upload.gemini-cancel-upload,
.gemini-term,
.gemini-limit-terms {
  &,
  * {
    font-family: "Google Sans Text" !important;
  }
}

.gemini-term *:not(.entribe-uploader-modal-title, strong) {
  font-weight: normal !important;
}

.gemini * {
  font-family: "Google Sans Text" !important;

  #google_translate_element {
    .goog-te-gadget .goog-te-combo {
      font-size: 14px !important;
      border: none !important;
      background-color: #f0f4f9 !important;
      padding: 0 16px !important;
    }
    svg {
      fill: #444746;
    }
  }

  .checkbox_custom {
    * {
      font-size: 14px;
    }

    .text_term {
      color: #131314;
    }
  }
}

body,
body * {
  transform-style: initial;
  -moz-transform-style: initial;

  .preloader-container {
    width: 100%;
    height: 100vh;
    padding: 100px;
    text-align: center;
    margin: auto;

    img {
      width: 150px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

body {
  // background-color: #3e3e3e;
  background-color: #fff;
}

/* Button */
.btn-sm {
  padding: 3px 15px;
  min-width: 110px;
}

.btn-orange {
  background-color: $orange-color;
  color: #fff;
  font-weight: bold;

  &:hover {
    background-color: $orange-hover-color;
    color: #fff;
  }
}

/* End  */

.hide {
  display: none !important;
}

.loading-state {
  & > *:not(.loading-bar) {
    opacity: 0.4;
    pointer-events: none;
  }
}

/* Loading Bar */
.loading-bar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  overflow: hidden;
  display: block;
  margin-top: -3px;
  z-index: 999;

  .line {
    position: absolute;
    opacity: 0.4;
    background: #fff;
    width: 100%;
    height: 5px;
  }

  .subline {
    position: absolute;
    background: $orange-color;
    height: 5px;
  }

  .inc {
    animation: increase 5s infinite;
  }

  .dec {
    animation: decrease 5s 1s infinite;
  }

  &.gemini {
    .line {
      background: unset;
    }

    .subline {
      background: linear-gradient(
        90deg,
        #217bfe 0%,
        #078efb 34.5%,
        #ac87eb 72%,
        #ee4d5d 100%
      );
    }
  }

  @keyframes increase {
    from {
      left: -5%;
      width: 5%;
    }

    to {
      left: 130%;
      width: 100%;
    }
  }

  @keyframes decrease {
    from {
      left: -80%;
      width: 80%;
    }

    to {
      left: 110%;
      width: 10%;
    }
  }
}
/* End Loading Bar */

.widget {
  &.disabled {
    opacity: 0.7;

    * {
      pointer-events: none;
    }
  }
}

.widget-container {
  position: absolute;
  display: block;
  width: 100%;
  height: fit-content;
  min-height: 100%;
  padding: 20px 0 50px;

  &.disabled {
    opacity: 0.7;

    * {
      pointer-events: none;
    }
  }

  .widget-block {
    display: block;
    width: 420px;
    background-color: #fff;
    margin: auto;
    border: 1px solid $light-gray-color;

    .widget-header {
      min-height: 50px;
      padding: 0 15px;
      text-align: center;
      line-height: 50px;
      font-size: 20px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $orange-color;

      img {
        width: 55%;
        margin: 15px auto;
      }
    }

    .widget-body {
      .widget-form {
        padding: 0 15px;

        input[type="text"],
        input[type="email"],
        textarea {
          &:focus {
            box-shadow: none;
            border-color: $dark-color-hover;
          }
        }

        .form-group {
          small {
            display: block;
            margin-top: 2px;
            font-size: 12px;
            color: $red-color;
          }
        }

        .error-msg {
          p {
            color: $red-color;
            font-size: 14px;
          }
        }
      }
    }

    .widget-agreement {
      padding: 15px;
      font-size: 14px;

      .form-check-input {
        margin-top: 4px;
      }

      a {
        color: $orange-color;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .widget-footer {
      padding-top: 15px;
      text-align: center;

      .btn {
        margin: 10px;
      }

      .signature {
        text-align: left;
        padding: 15px;
        font-size: 11px;
        color: #696969;

        img {
          width: 55px;
          margin-left: 5px;
        }
      }
    }

    .hashtag {
      color: #fff;
      background-color: $gray-color;
      display: inline-block;
      padding: 0.25em 0.4em;
      margin: 0 2px;
      font-size: 75%;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:hover {
        background-color: #757575;
        cursor: pointer;
      }

      &.active {
        background-color: $orange-color;
      }
    }
  }
}

body > .skiptranslate {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccd0d4;
    border-radius: 20px;
  }
}

svg {
  vertical-align: initial;
}

.gemini-upload-dropzone,
.gemini.thumbnail_wrapper {
  border: none;
  background-image: url("assets/img/gemini/gemini-upload-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 215px;
}

@media print {
  #google_translate_element,
  #google_translate_element_teampixel {
    display: none;
  }
}

/* Should import at the end to override all CSS */
@import "./assets/styles/responsive.scss";
