@import "../../../assets/styles/variables.scss";

.widget-container {
  .widget-upload {
    .widget-body {
      .widget-media {
        height: 150px;
        position: relative;
        border-top: 1px dashed $dark-color-hover;
        border-bottom: 1px dashed $dark-color-hover;
        color: #fff;
        text-align: center;

        &:hover {
          .dropzone {
            box-shadow: inset 0px 0px 8px #202020;
          }
        }

        .preview-img {
          height: 100%;
          width: 100%;
          display: flex;
          padding: 15px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          align-items: center;
          justify-content: center;
          background-size: cover;
          background-position: center;
          cursor: pointer;

          p {
            color: #fff;
            display: block;
            margin: auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            align-items: center;
            z-index: 1;
          }

          .dropzone {
            background-color: $dark-color-hover;
            width: 100%;
            height: 100%;
            position: absolute;
            text-align: center;
            opacity: 0.8;

            &:focus {
              outline: none;
            }
          }

          .preview-txt-default {
            z-index: 1;
            pointer-events: none;
          }
        }

        svg {
          margin-bottom: 10px;
        }
      }

      .widget-form {
        margin-top: 25px;

        .form-group {
          margin-bottom: 5px;
        }
      }

      .widget-hashtag {
        label {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 0;
        }

        .list-hashtag {
          display: block;
          min-height: 25px;
          margin-bottom: 10px;

          small {
            font-style: italic;
            font-size: 12px;
          }
        }
      }
    }
  }

  .widget-block.widget-thankyou {
    box-shadow: 3px 5px 5px 0px #ddd;

    .widget-body {
      h3 {
        width: 100%;
        display: block;
        text-align: center;
        background: $orange-color;
        padding: 15px 0;
        color: #fff;
        font-size: 20px;
      }

      .uploaded-media {
        margin-top: 25px;
        padding: 0 15px;
        position: relative;

        img {
          width: 100%;
          max-height: 300px;
          object-fit: cover;
        }

        svg {
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #fff;
          border-radius: 50%;
          border: 1px solid #fff;
        }
      }

      .top-uploaded-media-list {
        display: block;
        padding: 0 15px;
        margin-top: 20px;

        h4 {
          color: $orange-color;
          text-align: center;
          font-size: 18px;
        }

        .row {
          padding: 0 10px;

          .col-6 {
            padding: 0;
          }
        }

        img {
          width: calc(100% - 8px);
          height: 120px;
          object-fit: cover;
          display: block;
          margin: 5px auto;
        }

        svg {
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #fff;
          border-radius: 50%;
          border: 1px solid #fff;
        }
      }
    }
  }
}
