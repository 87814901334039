@import "../../../../assets/styles/variables.scss";
@import "../../../../assets/styles/fonts.scss";
$button-color: #de8703;

@media only screen and (max-width: 400px) {
  .widget-circlekcanada.widget-container {
    .container {
      .widget-block {
        padding: 0 !important;
        border: 0 !important;
        width: 100% !important;
        margin: 0 !important;
        .container {
          width: 85% !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .widget-circlekcanada.widget-container {
    .container {
      .widget-block.widget-welcome,
      .widget-block.widget-upload,
      .widget-block.widget-thankyou {
        width: 100%;
        border: 0 !important;
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .widget-circlekcanada.widget-container {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
      padding: 0 !important;
    }
    .w-50 {
      width: 100% !important;
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      z-index: -1;
    }

    .widget-block.widget-welcome,
    .widget-block.widget-upload,
    .widget-block.widget-thankyou {
      overflow: auto;
      max-height: 100vh;
      width: 400px;
      border: 1px solid #ced4da;
      margin-top: 0;
      .container {
        width: 85% !important;
      }
      .widget-header {
        padding: 15px;
        width: 100%;
        img {
          margin: 0 auto;
          display: block;
          width: auto;
          padding: 0;
          max-width: 100%;
          height: auto !important;
          max-height: 120px !important;
        }
      }

      .widget-body {
        display: block !important;
      }
    }

    .widget-upload.widget-block {
      .widget-body {
        .circlekcanada-media {
          position: relative;
          padding-top: 21px;
          padding-right: 0;
        }

        .text-wrapper {
          display: none;
          p {
            margin: 1.5rem 0;
          }
        }

        .widget-media {
          height: 150px;
          width: 100% !important;
          position: relative;
          color: #fff;
          text-align: center;

          .preview-img {
            height: 100%;
            width: 100%;
            display: flex;
            padding: 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            align-items: center;
            justify-content: center;
            background-size: cover;
            background-position: center;
            cursor: pointer;

            .dropzone {
              width: 100%;
              height: 100%;
              position: absolute;
              text-align: center;
              opacity: 0.8;

              &:focus {
                outline: none;
              }
            }

            .preview-txt-default {
              z-index: 1;
              pointer-events: none;
            }
          }

          svg {
            margin-bottom: 10px;
          }
        }

        .text {
          position: absolute;
          color: #000;
          display: block;
          margin: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          align-items: center;
          z-index: 1;
          width: 100%;
          top: 105px;
        }
        .hide {
          opacity: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .widget-circlekcanada.widget-container {
    .container {
      max-width: 100%;
    }
  }
}

@media only screen and (min-width: 769px) {
  .widget-circlekcanada.widget-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
