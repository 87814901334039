@import "../../../../assets/styles//variables.scss";

$ucdavis-color: #0f2855;

.widget-ucdavis.widget-container {
  background-image: url("../../../../assets/img/ucdavis/UCDavisBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 0;
  .widget-block.widget-welcome,
  .widget-block.widget-upload,
  .widget-block.widget-thankyou {
    overflow: auto;
    max-height: 100vh;
    width: 400px;
    border: 1px solid #ced4da;
    .widget-header {
      padding: 20px;
      img {
        margin: auto;
        display: block;
        width: auto;
        padding: 0;
        max-width: 100%;
        height: auto !important;
      }
    }

    .widget-body {
      .button-wrapper {
        margin: 20px 0 10px 0;
      }
    }

    .signature {
      margin-top: 2rem;
      padding: 0;
      p {
        width: 100%;
        height: 8px;
        margin: 0;
        font-weight: 500;
        font-size: 11px;
        min-height: 20px;
      }
    }

    .widget-footer {
      padding: 15px;
    }
  }

  .widget-welcome {
    .widget-body {
      .text-title {
        margin: 10px 0;
      }

      .row-ucdavis {
        width: 100%;
        height: auto;
        position: relative;

        > img {
          width: auto;
          height: 60px;
          margin: auto;
          display: block;
        }

        &.sign {
          > div {
            text-align: left;
            margin-top: 5px;

            > p {
              width: 100%;
              height: 8px;
              margin: 0;
              font-weight: 500;
              font-size: 11px;
              padding-left: 20px;

              > img {
                height: 100%;
              }
            }
          }
        }
      }

      .text-container {
        padding: 0 60px;
      }

      .textStyle {
        font-weight: 600;
        text-align: center;
        font-size: 20px;
        font-family: $primary-font;
        line-height: 1.6;
      }

      .logo-container {
        padding: 20px;
      }

      .logo-ucdavis {
        line-height: 70px;
        height: auto !important;
        position: relative;
        display: block;
        margin: auto;
        overflow: hidden;
        text-align: center;
        width: auto;
      }
      .button-wrapper {
        margin: 20px 0 10px 0;
        .btn.btn-primary {
          background-color: $ucdavis-color;
          border: 1px solid $ucdavis-color;
          width: 180px;
          font-weight: bold;

          &:hover {
            opacity: 0.8;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .widget-upload.widget-block {
    .widget-header {
      padding: 20px 15px;
    }

    .widget-agreement {
      .form-check > input {
        height: 20px;
        width: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: 1px solid #ced4da;
        border-radius: 4px;
        outline: none;
        transition-duration: 0.3s;
        background-color: #fff;
        cursor: pointer;
      }

      .form-check > input:checked {
        border: 1px solid $ucdavis-color;
        background-color: $ucdavis-color;
      }

      .form-check > label {
        padding: 0.35rem;
        margin-left: 5px;
        font-weight: 400;
        color: #999;
        cursor: pointer;
      }

      .form-check > input:checked + label::before {
        content: "";
        display: flow-root;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        text-align: center;
        color: #fff;
        position: absolute;
        width: 7px;
        height: 12px;
        top: 6px;
        left: 6px;
      }

      .form-check-label {
        font-family: junction-regular !important;

        .term {
          font-family: junction-regular !important;
          color: $ucdavis-color;
        }
      }
    }

    .widget-footer {
      padding: 15px;
    }
    .widget-body {
      .widget-form {
        margin-top: 0;
        .general-message {
          min-height: 31px;
          font-size: 14px;
          padding: 5px 0;
        }
        .general-message.error {
          color: #e60000;
        }
        .error-msg p,
        .form-group small {
          color: #e60000;
        }

        .input-wrapper {
          .uploader-error-message {
            font-weight: 400;
            min-height: 18px;
          }
          .social-media-input {
            display: flex;
            margin-bottom: 18px;
            span {
              padding-right: 2px;
              padding-top: 3px;
              font-family: junction-regular;
            }
            
            input {
              padding-left: 8px;
              // padding: 0 !important;
            }
          }
        }

        .input-group {
          .input-group-prepend {
            &.focused {
              border: 1px solid #343a40;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;

              .input-group-text {
                border: none;
              }
            }

            .input-group-text {
              background-color: #fff;
              padding-right: 2px;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;

              &.disabled {
                background-color: #e9ecef;
              }
            }
          }

          input {
            border-left: none;
            padding-left: 0;
            background-color: #fff;
          }

          * {
            transition: none;
          }
        }

        .form-check {
          line-height: 22px;

          .form-check-label {
            font-size: 14px;
          }
        }

        .widget-hashtag {
          .list-hashtag {
            .hashtag.active {
              background-color: $ucdavis-color;
            }
          }
        }
      }

      .widget-media {
        .preview-img {
          .dropzone {
            background-color: $ucdavis-color;
          }
        }
      }
    }
  }

  .widget-thankyou {
    box-shadow: none;
    .widget-header {
      padding: 20px;
    }
    .widget-body {
      .thankyou-c0F2855ontent {
        padding: 15px;

        h5 {
          color: $ucdavis-color;
          text-align: center;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 25px;
        }

        p {
          margin-bottom: 10px;
        }

        span {
          display: block;
        }
      }

      h3 {
        background-color: $ucdavis-color;
      }
    }
  }

  .widget-block {
    width: 400px;
    border: none;
    background-color: rgba(255, 255, 255, 1);

    .widget-agreement {
      a {
        color: $ucdavis-color;
      }
    }

    .widget-footer {
      .button {
        background-color: $ucdavis-color;
        border: 1px solid $ucdavis-color;
        color: #fff;
        &:hover {
          opacity: 0.8;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .btn.btn-primary {
        background-color: $ucdavis-color;
        border: 1px solid $ucdavis-color;

        &:hover {
          opacity: 0.8;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
