/* Desktop - Screen width is less than lg breakpoint (1200px) */
@include media-breakpoint-down(lg) {
}

/* Tablet - Screen width is less than md breakpoint (992px) */
@include media-breakpoint-down(md) {
  .widget-teampixel {
    &.widget-container {
      .widget-block {
        &.widget-upload,
        &.widget-thankyou {
          float: none;
        }

        &.widget-thankyou {
          .widget-body {
            min-height: auto;
            padding: 25px 0;
          }
        }
      }

      .mobile-gallery-container {
        display: flex;
        width: 500px;
        margin: auto;

        .gallery {
          display: inline-block;
          width: 100%;
          height: 200px;

          .block-parent-wrapper {
            width: 100%;

            .block-parent {
              width: 1900px;
              left: -729px;
              top: -600px;

              width: 2380px;
              left: -820px;
              top: -1398px;

              width: 2400px;
              left: -950px;
              top: -1400px;

              animation-name: mobileZoomOut;
              transform: scale(0.63);
              --maxScale: 0.63;
              --minScale: 0.21;
              transform-origin: center center;
            }
          }
        }
      }
    }
  }
}

/* Landscape device - Screen width is less than sm breakpoint (768px) */
@include media-breakpoint-down(sm) {
}

/* Mobile - Screen width is less than xs breakpoint (576px) */
@include media-breakpoint-down(xs) {
}

@media only screen and (max-width: 580px) {
  .widget-teampixel {
    &.widget-container {
      .widget-block.widget-upload {
        width: 100%;
        padding: 15px;

        .widget-header {
          h2 {
            font-size: 32px;
          }
        }

        .btn-submit {
          display: block;
          width: 100%;
          margin-top: 15px;
        }
      }

      .mobile-gallery-container {
        width: 100%;

        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -930px;
              top: -1650px;
              transform: scale(0.7);
              --maxScale: 0.7;
              --minScale: 0.28;
            }
          }
        }
      }
    }

    .upload-status-file-name {
      margin: 0 0 0 12px !important;
    }
  }
}

@media only screen and (max-width: 550px) {
  .widget-teampixel {
    &.widget-container {
      .mobile-gallery-container {
        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -943px;
              transform: scale(0.64);
              --maxScale: 0.64;
              --minScale: 0.25;
            }
          }
        }
      }
    }
  }

  .google-terms-modal {
    .modal-header {
      padding: 15px;
    }

    .modal-body {
      padding: 20px 50px 30px 50px;
    }
  }
}

@media only screen and (max-width: 530px) {
  .widget-teampixel {
    &.widget-container {
      .widget-block.widget-thankyou {
        width: 100%;
      }

      .mobile-gallery-container {
        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -950px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .widget-teampixel {
    &.widget-container {
      .mobile-gallery-container {
        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -964px;
              transform: scale(0.63);
              --maxScale: 0.63;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .widget-teampixel {
    &.widget-container {
      .widget-block.widget-upload {
        .widget-header {
          h2 {
            font-size: 28px;
          }
        }
      }

      .mobile-gallery-container {
        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -980px;
              top: -1630px;
              transform: scale(0.58);
              --maxScale: 0.58;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .widget-teampixel {
    &.widget-container {
      .mobile-gallery-container {
        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -986px;
              top: -1613px;
              transform: scale(0.54);
              --maxScale: 0.54;
            }
          }
        }
      }
    }
  }

  .legal-header {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 430px) {
  .widget-teampixel {
    &.widget-container {
      .mobile-gallery-container {
        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -1005px;
              top: -1600px;
              transform: scale(0.5);
              --maxScale: 0.5;
              --minScale: 0.2;
            }
          }
        }
      }
    }
  }

  .google-terms-modal {
    .modal-header {
      padding: 15px;
    }

    .modal-body {
      padding: 20px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .widget-teampixel {
    &.widget-container {
      .widget-block.widget-upload {
        .widget-header {
          h2 {
            font-size: 28px;
          }
        }
      }

      .mobile-gallery-container {
        .gallery {
          height: 180px;

          .block-parent-wrapper {
            .block-parent {
              left: -1018px;
              top: -1600px;
              transform: scale(0.48);
              --maxScale: 0.48;
            }
          }
        }
      }
    }
  }

  .google-terms-modal {
    .modal-header {
      .google-terms-title {
        font-size: 18.5px;
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .widget-teampixel {
    &.widget-container {
      .widget-block.widget-thankyou {
        .widget-body {
          padding: 10px 0;

          h2 {
            font-size: 24px;
          }

          ul {
            li {
              a {
                font-size: 12px;
              }
            }
          }
        }
      }

      .mobile-gallery-container {
        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -1030px;
              top: -1600px;
              transform: scale(0.45);
              --maxScale: 0.45;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .widget-teampixel {
    &.widget-container {
      .widget-block.widget-upload {
        .widget-header {
          h2 {
            font-size: 24px;
          }
        }

        .widget-body {
          .widget-form {
            .form-control {
              font-size: 12px;
            }
          }
        }
      }

      .mobile-gallery-container {
        .gallery {
          height: 160px;

          .block-parent-wrapper {
            .block-parent {
              left: -1040px;
              transform: scale(0.42);
              --maxScale: 0.42;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 340px) {
  .widget-teampixel {
    &.widget-container {
      .mobile-gallery-container {
        .gallery {
          .block-parent-wrapper {
            .block-parent {
              left: -1045px;
              transform: scale(0.4);
              --maxScale: 0.4;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .widget-teampixel {
    &.widget-container {
      .mobile-gallery-container {
        .gallery {
          height: 140px;

          .block-parent-wrapper {
            .block-parent {
              left: -1055px;
              transform: scale(0.37);
              --maxScale: 0.37;
            }
          }
        }
      }
    }
  }
}

@keyframes mobileZoomOut {
  0% {
    transform-origin: center center;
    transform: scale(var(--maxScale));
  }

  50% {
    transform-origin: center center;
    transform: scale(var(--minScale));
  }

  100% {
    transform-origin: center center;
    transform: scale(var(--maxScale));
  }
}
