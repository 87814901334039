.widget-entribe {
  * {
    font-family: "Open Sans", "Montserrat", "Arial", "sans-serif";
  }

  &.widget-container {
    .widget-block {
      border: none;

      &.widget-upload,
      &.widget-thankyou {
        width: 500px;
        padding: 15px 30px;
        background-color: #fafafa;
        box-shadow: none;
        float: right;

        .widget-header {
          text-align: left;

          .sub-header {
            margin-top: 12px;
            color: #626262;
            font-size: 20px;
            text-align: center;
            font-weight: normal;
            white-space: pre-wrap;
            line-height: 1.5;
            opacity: 0;
          }

          .sub-header-animation {
            opacity: 1;
            transition: visibility 0s linear 1s, opacity 1s linear;
          }

          .logo {
            display: block;
            width: 100px;
            height: 100px;
            margin: 10px auto 15px auto;
            background-repeat: no-repeat;
            background-size: 90%;
            background-position: center;
          }

          h1 {
            display: inline-block;
            min-height: 48px;
            color: #000;
            margin: 0 auto 0 5px;
            position: absolute;
            top: 35px;
          }

          h2 {
            min-height: 43px;
            font-weight: bold;
            color: #626262;
            font-size: 36px;
            text-align: center;
            margin-bottom: 0;
          }
        }

        .widget-body {
          .widget-form {
            margin-top: 10px;
            padding: 0;

            .form-group {
              .input-group {
                .input-group-prepend {
                  .input-group-text {
                    border: none;
                    background: none;
                    padding-right: 2px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;

                    &.disabled {
                      background-color: #e9ecef;
                    }
                  }
                }

                input {
                  padding-left: 0;
                }
              }
            }

            input,
            textarea,
            select {
              border: none;
            }

            textarea {
              resize: none;
            }

            select {
              padding-left: 5px;
              color: #495057;

              &:invalid {
                color: #6c757d;
              }
            }

            .react-select__control {
              border: none;

              .react-select__placeholder {
                font-size: 14px;
                color: #495057;
              }
              .react-select__value-container {
                .react-select__single-value {
                  font-size: 0.875rem;
                }
              }
            }

            .error-msg {
              p {
                font-size: 12px;
              }
            }
          }

          input[type="file"] {
            display: none;
          }
        }

        .widget-agreement {
          margin-top: 10px;
          padding: 0;

          a,
          label {
            font-size: 12px;
            color: #495057;
          }

          .google-terms {
            cursor: pointer;
          }
        }

        .widget-footer {
          .signature {
            padding: 15px 0;

            img {
              width: 90px;
            }
          }
        }

        .btn {
          border-radius: 0;

          &.btn-primary {
            width: 100%;
            padding: 15px;
            margin: 20px auto;
            background-color: #e15c26;
            border-color: #e15c26;
          }

          &.btn-success {
            padding: 10px 50px;
            background-color: #e15c26;
            border-color: #e15c26;
          }
        }
      }

      &.widget-thankyou {
        .entribe-body-thankyou {
          padding: 0 !important;
          .social-logo {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            display: inline-block;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .widget-body {
          min-height: 760px;
          padding: 0 0 55px 0;
          align-items: center;
          display: flex;
          justify-content: center;
          margin-top: 15px;
          .body-container {
            min-height: 760px;
            h2,
            p {
              color: #626262;
            }
            .space {
              margin-top: 120px;
            }
            h2 {
              margin-bottom: 15px;
              margin-top: 15px;
            }

            p {
              margin-bottom: 0;
            }

            ul {
              li {
                list-style: none;
              }
            }
          }
        }
      }
    }

    .gallery {
      padding-left: 0;

      .block-parent-wrapper {
        overflow: hidden;
        width: 400px;
        height: 100%;
        z-index: 20;
        margin: auto;
        top: 0;
        position: relative;

        .block-parent {
          width: 800px;
          margin: auto;
          position: absolute;
          left: -200px;
          top: -400px;
          transform: scale(1.35);
          animation-name: zoomOut;
          animation-duration: 10s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-delay: 3s;

          .block-child {
            width: 400px;
            float: left;

            .image-holder {
              position: relative;
              width: 200px;
              height: 400px;
              margin: auto;
              overflow: hidden;
              float: left;

              .images {
                width: 400px;
                height: 800px;
                position: relative;
                animation-duration: 7.5s; // Time to rotate
                animation-iteration-count: infinite;
                animation-timing-function: ease-out;
                animation-delay: 3s;

                img {
                  width: 200px;
                  height: 400px;
                  object-fit: cover;
                }
              }

              .clockwise {
                animation-name: clockwise;
                left: 0;
                top: 0;
              }

              .counterClockwise {
                animation-name: counterClockwise;
                left: -200px;
                top: -400px;
              }
            }
          }
        }
      }
    }

    .mobile-gallery {
      display: none;
    }
  }
}

@keyframes clockwise {
  0% {
    left: 0;
    top: 0;
  }
  5% {
    left: 0;
    top: 0;
  }

  20% {
    left: -200px;
    top: 0;
  }
  30% {
    left: -200px;
    top: 0;
  }

  45% {
    left: -200px;
    top: -400px;
  }
  55% {
    left: -200px;
    top: -400px;
  }

  70% {
    left: 0;
    top: -400px;
  }
  80% {
    left: 0;
    top: -400px;
  }

  95% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: 0;
  }
}

@keyframes counterClockwise {
  0% {
    left: -200px;
    top: -400px;
  }
  5% {
    left: -200px;
    top: -400px;
  }

  20% {
    left: 0;
    top: -400px;
  }
  30% {
    left: 0;
    top: -400px;
  }

  45% {
    left: 0;
    top: 0;
  }
  55% {
    left: 0;
    top: 0;
  }

  70% {
    left: -200px;
    top: 0;
  }
  80% {
    left: -200px;
    top: 0;
  }

  95% {
    left: -200px;
    top: -400px;
  }
  100% {
    left: -200px;
    top: -400px;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.35);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1.35);
  }
}

.google-terms-modal {
  font-family: "Product Sans";
  color: #495057;

  .modal-header {
    text-align: center;
    padding: 20px;

    .google-terms-title {
      font-weight: bold;
      margin: 0;
      width: 100%;
    }
  }

  .modal-body {
    text-align: justify;
    padding: 30px 60px 40px 60px;
  }
}
