@import "../../../../assets/styles/variables.scss";

.widget-inkedibles.widget-container {
  background-image: url("../../../../assets/img/inkedibles/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  margin: 0 !important;
  padding: 20px 0 70px !important;

  .widget-block {
    border: none;
  }

  .groupStyles {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .groupBadgeStyles {
      background-color: #ebecf0;
      border-radius: 2em;
      color: #172b4d;
      display: inline-block;
      font-size: 12px;
      font-weight: normal;
      line-height: 1;
      min-width: 1px;
      padding: 0.16666666666667em 0.5em;
      text-align: center;
    }
  }

  .react-select__control {
    .react-select__value-container {
      padding-top: 2px;
      padding-bottom: 2px;
      padding-right: 5px;
      padding-left: 5px;
      font-size: 0.875rem;

      &:focus {
        box-shadow: none;
        border-color: #343a40;
        color: #495057;
        background-color: #fff;
        outline: 0;
      }

      .react-select__placeholder {
        width: 100%;
      }
    }
  }

  .css-1pahdxg-control {
    box-shadow: none !important;
    border-color: #343a40 !important;
    color: #495057 !important;
    background-color: #fff;
    outline: 0;
  }

  .react-select__option {
    padding-left: 25px;
    font-size: 14px;
  }

  .widget-header {
    width: 100%;

    img {
      width: 100% !important;
      margin-bottom: 0 !important;
    }
  }

  .widget-upload {
    box-shadow: 3px 5px 5px 3px #dddddd !important;

    .widget-body {
      .button {
        width: 100%;
        line-height: 2.5em;

        .inputFile {
          display: none;
        }
      }

      .file-select {
        font-size: 14px;
        color: #ff1212;
        min-height: 20px;
        margin-bottom: 10px;

        p {
          margin-bottom: 0 !important;
        }
      }

      .widget-media {
        height: 150px;
        position: relative;
        border-top: 1px dashed $dark-color-hover;
        border-bottom: 1px dashed $dark-color-hover;
        color: #fff;
        text-align: center;

        &:hover {
          .dropzone {
            box-shadow: inset 0px 0px 8px #ca3b0d;
          }
        }

        .preview-img {
          height: 100%;
          width: 100%;
          display: flex;
          padding: 15px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          align-items: center;
          justify-content: center;
          background-size: cover;
          background-position: center;
          cursor: pointer;

          p {
            color: #fff;
            display: block;
            margin: auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            align-items: center;
            z-index: 1;
          }

          .dropzone {
            background-color: $dark-color-hover;
            width: 100%;
            height: 100%;
            position: absolute;
            text-align: center;
            opacity: 0.8;

            &:focus {
              outline: none;
            }
          }

          .preview-txt-default {
            z-index: 1;
            pointer-events: none;
          }
        }

        svg {
          margin-bottom: 10px;
        }
      }

      .widget-form {
        margin-top: 0 !important;

        .form-group {
          margin-bottom: 5px;

          .widget-inkedibles-form {
            height: calc(1.5em + 0.5rem + 4px) !important;
          }

          .text-area {
            width: 100%;
            padding-top: 2px;
            padding-bottom: 2px;
            padding-right: 58px;
            padding-left: 5px;
            font-size: 0.875rem;
            background-color: hsl(0, 0%, 100%);
            border-color: hsl(0, 0%, 80%);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            min-height: 55px;
            margin-bottom: 16px;

            &:focus {
              box-shadow: none;
              border-color: #343a40;
              color: #495057;
              background-color: #fff;
              outline: 0;
            }
          }

          .input-group {
            .input-group-prepend {
              &.focused {
                border: 1px solid #343a40;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                .input-group-text {
                  border: none;
                }
              }

              .input-group-text {
                background-color: #fff;
                padding-right: 2px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                &.disabled {
                  background-color: #e9ecef;
                }
              }
            }

            input {
              border-left: none;
              padding-left: 0;
            }

            * {
              transition: none;
            }
          }
        }

        .small {
          min-height: 20px;
        }

        .small-no-min-height {
          min-height: 0;
        }

        .error-msg {
          p {
            min-height: 0px;
          }
        }
      }

      .widget-hashtag {
        label {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 0;
        }

        .list-hashtag {
          display: block;
          min-height: 25px;
          margin-bottom: 10px;

          small {
            font-style: italic;
            font-size: 12px;
          }
        }
      }

      .widget-agreement {
        width: 100%;
        padding-top: 0 !important;

        .right-col {
          padding-left: 0;
        }
      }

      .inkedibles-description {
        margin: 0 0 24px 0;
        text-align: center;

        a {
          color: #e2582b;
        }
      }
    }
  }

  .widget-thankyou {
    .inkedibles-header {
      color: #3c2415;
    }

    .inkedibles-body {
      h3 {
        background: #3c2415 !important;
      }

      .inkedibles {
        h4 {
          color: #3c2415 !important;
        }
      }
    }

    .inkedibles-footer {
      button {
        margin: 30px;
      }
    }

    .widget-body {
      h3 {
        width: 100%;
        display: block;
        text-align: center;
        background: $orange-color;
        padding: 15px 0;
        color: #fff;
        font-size: 20px;
      }

      .uploaded-media {
        margin-top: 25px;
        padding: 0 15px;
        position: relative;

        img {
          width: 100%;
          max-height: 300px;
          object-fit: cover;
        }

        svg {
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #fff;
          border-radius: 50%;
          border: 1px solid #fff;
        }
      }

      .top-uploaded-media-list {
        display: block;
        padding: 0 15px;
        margin-top: 20px;

        h4 {
          color: $orange-color;
          text-align: center;
          font-size: 18px;
        }

        .row {
          padding: 0 10px;

          .col-6 {
            padding: 0;
          }
        }

        img {
          width: calc(100% - 8px);
          height: 120px;
          object-fit: cover;
          display: block;
          margin: 5px auto;
        }

        svg {
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: #fff;
          border-radius: 50%;
          border: 1px solid #fff;
        }
      }
    }
  }
}
